import CustomToast from "components/common/CustomToast";
import { chatTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { chatService } from "services/Ldb";

export const chatActions = {
    createConversation,
    resetCreateConversationSuccess,
    getSingleConversation,
    sendAiMessage,
    resetSendAiMessageSuccess,
    updateChatData,
    resetUpdateChatDataSuccess,
};

function createConversation(data) {
    return (dispatch) => {
        dispatch(request(chatTypes.CREATE_CONVERSATION_REQUEST));

        chatService.createConversation(data).then(
            (res) => {
                dispatch(success(chatTypes.CREATE_CONVERSATION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(chatTypes.CREATE_CONVERSATION_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleConversation(conversationId) {
    return (dispatch) => {
        dispatch(request(chatTypes.FETCH_CONVERSATION_REQUEST));

        chatService.getConversation(conversationId).then(
            (res) => {
                dispatch(success(chatTypes.FETCH_CONVERSATION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(chatTypes.FETCH_CONVERSATION_FAILURE, error?.message));
                }
            }
        );
    };
}

function sendAiMessage(data, setChats) {
    return (dispatch) => {
        dispatch(request(chatTypes.SEND_AI_CHAT_REQUEST));

        chatService.sendChat(data).then(
            (res) => {
                dispatch(success(chatTypes.SEND_AI_CHAT_SUCCESS, res?.data));
                setTimeout(() => {
                    setChats((prev) => [
                        ...prev,
                        { loading: true, conversationId: data?.conversationId },
                    ]);
                }, 500);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(chatTypes.SEND_AI_CHAT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateChatData(data) {
    return (dispatch) => {
        dispatch(request(chatTypes.UPDATE_CHAT_DATA_REQUEST));

        chatService.updateChatData(data).then(
            (res) => {
                dispatch(success(chatTypes.UPDATE_CHAT_DATA_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(chatTypes.UPDATE_CHAT_DATA_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetCreateConversationSuccess() {
    return (dispatch) => {
        dispatch(success(chatTypes.CREATE_CONVERSATION_SUCCESS, null));
    };
}

function resetSendAiMessageSuccess() {
    return (dispatch) => {
        dispatch(success(chatTypes.SEND_AI_CHAT_SUCCESS, null));
    };
}

function resetUpdateChatDataSuccess() {
    return (dispatch) => {
        dispatch(success(chatTypes.UPDATE_CHAT_DATA_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
