import { workFlowTypes } from "redux/Ldb/types";

const initialState = {
    fetchingWorkFlowList: false,
    fetchedWorkFlowListSuccess: null,
    fetchedWorkFlowListFailure: null,
    createWorkFlow: false,
    createWorkFlowSuccess: null,
    createWorkFlowFailure: null,
    deleteWorkFlow: false,
    deleteWorkFlowSuccess: null,
    deleteWorkFlowFailure: null,
    playOrPauseWorkFlow: false,
    playOrPauseWorkFlowSuccess: null,
    playOrPauseWorkFlowFailure: null,
};

export const workFlow = (state = initialState, action) => {
    switch (action.type) {
        case workFlowTypes.FETCH_WORKFLOW_LIST_REQUEST:
            return {
                ...state,
                fetchingWorkFlowList: true,
                fetchedWorkFlowListSuccess: null,
                fetchedWorkFlowListFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_LIST_SUCCESS:
            return {
                ...state,
                fetchingWorkFlowList: false,
                fetchedWorkFlowListSuccess: action.payload,
                fetchedWorkFlowListFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_LIST_FAILURE:
            return {
                ...state,
                fetchingWorkFlowList: false,
                fetchedWorkFlowListSuccess: null,
                fetchedWorkFlowListFailure: action.payload,
            };
        case workFlowTypes.CREATE_WORKFLOW_REQUEST:
            return {
                ...state,
                createWorkFlow: true,
                createWorkFlowSuccess: null,
                createWorkFlowFailure: null,
            };
        case workFlowTypes.CREATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                createWorkFlow: false,
                createWorkFlowSuccess: action.payload,
                createWorkFlowFailure: null,
            };
        case workFlowTypes.CREATE_WORKFLOW_FAILURE:
            return {
                ...state,
                createWorkFlow: false,
                createWorkFlowSuccess: null,
                createWorkFlowFailure: action.payload,
            };
        case workFlowTypes.DELETE_WORKFLOW_REQUEST:
            return {
                ...state,
                deleteWorkFlow: true,
                deleteWorkFlowSuccess: null,
                deleteWorkFlowFailure: null,
            };
        case workFlowTypes.DELETE_WORKFLOW_SUCCESS:
            return {
                ...state,
                deleteWorkFlow: false,
                deleteWorkFlowSuccess: action.payload,
                deleteWorkFlowFailure: null,
            };
        case workFlowTypes.DELETE_WORKFLOW_FAILURE:
            return {
                ...state,
                deleteWorkFlow: false,
                deleteWorkFlowSuccess: null,
                deleteWorkFlowFailure: action.payload,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_REQUEST:
            return {
                ...state,
                playOrPauseWorkFlow: true,
                playOrPauseWorkFlowSuccess: null,
                playOrPauseWorkFlowFailure: null,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS:
            return {
                ...state,
                playOrPauseWorkFlow: false,
                playOrPauseWorkFlowSuccess: action.payload,
                playOrPauseWorkFlowFailure: null,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_FAILURE:
            return {
                ...state,
                playOrPauseWorkFlow: false,
                playOrPauseWorkFlowSuccess: null,
                playOrPauseWorkFlowFailure: action.payload,
            };

        default:
            return state;
    }
};
