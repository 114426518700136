import { vendorTypes } from "redux/Ldb/types";

const initialState = {
    fetchingLdbVendors: false,
    fetchedLdbVendorsSuccess: null,
    fetchedLdbVendorsFailure: null,
    fetchingSingleVendor: false,
    fetchedSingleVendorSuccess: null,
    fetchedSingleVendorFailure: null,
    authenticatingGmail: false,
    authenticateGmailSuccess: null,
    authenticateGmailFailure: null,
    connectingGoogleWorkspace: false,
    connectGoogleWorkspaceSuccess: null,
    connectGoogleWorkspaceFailure: null,
    fetchingAllChannels: false,
    fetchedAllChannelsSuccess: null,
    fetchedAllChannelsFailure: null,
    fetchingVendorsByOrderType: false,
    fetchedVendorsByOrderTypeSuccess: null,
    fetchedVendorsByOrderTypeFailure: null,
};

export const vendor = (state = initialState, action) => {
    switch (action.type) {
        case vendorTypes.FETCH_LDB_VENDORS_REQUEST:
            return {
                ...state,
                fetchingLdbVendors: true,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: action.payload,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_FAILURE:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SINGLE_VENDOR_REQUEST:
            return {
                ...state,
                fetchingSingleVendor: true,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: action.payload,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_FAILURE:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_REQUEST:
            return {
                ...state,
                authenticatingGmail: true,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SUCCESS:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: action.payload,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_FAILURE:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspace: true,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: action.payload,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: action.payload,
            };

        case vendorTypes.FETCH_ALL_CHANNELS_REQUEST:
            return {
                ...state,
                fetchingAllChannels: true,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_SUCCESS:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: action.payload,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_FAILURE:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST:
            return {
                ...state,
                fetchingVendorsByOrderType: true,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: action.payload,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: action.payload,
            };
        default:
            return state;
    }
};
