import { orderTypes } from "redux/Ldb/types";


const initialState = {
    fetchingRfqs: false,
    fetchedRfqsSuccess: null,
    fetchedRfqsFailure: null,
    fetchingOrders: false,
    fetchedOrdersSuccess: null,
    fetchedOrdersFailure: null,
    fetchingSingleRfq: false,
    fetchedSingleRfqSuccess: null,
    fetchedSingleRfqFailure: null,
    fetchingSingleOrder: false,
    fetchedSingleOrderSuccess: null,
    fetchedSingleOrderFailure: null,
    fetchingQuotes: false,
    fetchedQuotesSuccess: null,
    fetchedQuotesFailure: null,
    fetchingSingleQuotes: false,
    fetchedSingleQuotesSuccess: null,
    fetchedSingleQuotesFailure: null,
    fetchingSingleQuoteResponses: false,
    fetchedSingleQuoteResponsesSuccess: null,
    fetchedSingleQuoteResponsesFailure: null,
    acceptQuote: false,
    acceptQuoteSuccess: null,
    acceptQuoteFailure: null,
    updatingOrder: false,
    updateOrderSuccess: null,
    updateOrderFailure: null,
    updatingRfq: false,
    updateRfqSuccess: null,
    updateRfqFailure: null,
    sendingRfqs: false,
    sendRfqsSuccess: null,
    sendRfqsFailure: null,
    deletingRfq: false,
    deleteRfqSuccess: null,
    deleteRfqFailure: null,
    createPurchaseOrder: false,
    createPurchaseOrderSuccess: null,
    createPurchaseOrderFailure: null,
    updatePurchaseOrder: false,
    updatePurchaseOrderSuccess: null,
    updatePurchaseOrderFailure: null,
    fetchingPurchaseOrder: false,
    fetchedPurchaseOrderSuccess: null,
    fetchedPurchaseOrderFailure: null,
    sendPurchaseOrder: false,
    sendPurchaseOrderSuccess: null,
    sendPurchaseOrderFailure: null,
    fetchingApprovedResponses: false,
    fetchedApprovedResponsesSuccess: null,
    fetchedApprovedResponsesFailure: null,
};

export const order = (state = initialState, action) => {
    switch (action.type) {
        case orderTypes.FETCH_RFQS_REQUEST:
            return {
                ...state,
                fetchingRfqs: true,
                fetchedRfqsSuccess: null,
                fetchedRfqsFailure: null,
            };
        case orderTypes.FETCH_RFQS_SUCCESS:
            return {
                ...state,
                fetchingRfqs: false,
                fetchedRfqsSuccess: action.payload,
                fetchedRfqsFailure: null,
            };
        case orderTypes.FETCH_RFQS_FAILURE:
            return {
                ...state,
                fetchingRfqs: false,
                fetchedRfqsSuccess: null,
                fetchedRfqsFailure: action.payload,
            };

        case orderTypes.FETCH_ORDERS_REQUEST:
            return {
                ...state,
                fetchingOrders: true,
                fetchedOrdersSuccess: null,
                fetchedOrdersFailure: null,
            };
        case orderTypes.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                fetchingOrders: false,
                fetchedOrdersSuccess: action.payload,
                fetchedOrdersFailure: null,
            };
        case orderTypes.FETCH_ORDERS_FAILURE:
            return {
                ...state,
                fetchingOrders: false,
                fetchedOrdersSuccess: null,
                fetchedOrdersFailure: action.payload,
            };

        case orderTypes.FETCH_SINGLE_RFQ_REQUEST:
            return {
                ...state,
                fetchingSingleRfq: true,
                fetchedSingleRfqSuccess: null,
                fetchedSingleRfqFailure: null,
            };
        case orderTypes.FETCH_SINGLE_RFQ_SUCCESS:
            return {
                ...state,
                fetchingSingleRfq: false,
                fetchedSingleRfqSuccess: action.payload,
                fetchedSingleRfqFailure: null,
            };
        case orderTypes.FETCH_SINGLE_RFQ_FAILURE:
            return {
                ...state,
                fetchingSingleRfq: false,
                fetchedSingleRfqSuccess: null,
                fetchedSingleRfqFailure: action.payload,
            };

        case orderTypes.FETCH_SINGLE_ORDER_REQUEST:
            return {
                ...state,
                fetchingSingleOrder: true,
                fetchedSingleOrderSuccess: null,
                fetchedSingleOrderFailure: null,
            };
        case orderTypes.FETCH_SINGLE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSingleOrder: false,
                fetchedSingleOrderSuccess: action.payload,
                fetchedSingleOrderFailure: null,
            };
        case orderTypes.FETCH_SINGLE_ORDER_FAILURE:
            return {
                ...state,
                fetchingSingleOrder: false,
                fetchedSingleOrderSuccess: null,
                fetchedSingleOrderFailure: action.payload,
            };
        case orderTypes.FETCH_QUOTES_REQUEST:
            return {
                ...state,
                fetchingQuotes: true,
                fetchedQuotesSuccess: null,
                fetchedQuotesFailure: null,
            };
        case orderTypes.FETCH_QUOTES_SUCCESS:
            return {
                ...state,
                fetchingQuotes: false,
                fetchedQuotesSuccess: action.payload,
                fetchedQuotesFailure: null,
            };
        case orderTypes.FETCH_QUOTES_FAILURE:
            return {
                ...state,
                fetchingQuotes: false,
                fetchedQuotesSuccess: null,
                fetchedQuotesFailure: action.payload,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_REQUEST:
            return {
                ...state,
                fetchingSingleQuotes: true,
                fetchedSingleQuotesSuccess: null,
                fetchedSingleQuotesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_SUCCESS:
            return {
                ...state,
                fetchingSingleQuotes: false,
                fetchedSingleQuotesSuccess: action.payload,
                fetchedSingleQuotesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTES_FAILURE:
            return {
                ...state,
                fetchingSingleQuotes: false,
                fetchedSingleQuotesSuccess: null,
                fetchedSingleQuotesFailure: action.payload,
            };
        case orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_REQUEST:
            return {
                ...state,
                fetchingSingleQuoteResponses: true,
                fetchedSingleQuoteResponsesSuccess: null,
                fetchedSingleQuoteResponsesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_SUCCESS:
            return {
                ...state,
                fetchingSingleQuoteResponses: false,
                fetchedSingleQuoteResponsesSuccess: action.payload,
                fetchedSingleQuoteResponsesFailure: null,
            };
        case orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_FAILURE:
            return {
                ...state,
                fetchingSingleQuoteResponses: false,
                fetchedSingleQuoteResponsesSuccess: null,
                fetchedSingleQuoteResponsesFailure: action.payload,
            };
        case orderTypes.ACCEPT_QUOTE_REQUEST:
            return {
                ...state,
                acceptQuote: true,
                acceptQuoteSuccess: null,
                acceptQuoteFailure: null,
            };
        case orderTypes.ACCEPT_QUOTE_SUCCESS:
            return {
                ...state,
                acceptQuote: false,
                acceptQuoteSuccess: action.payload,
                acceptQuoteFailure: null,
            };
        case orderTypes.ACCEPT_QUOTE_FAILURE:
            return {
                ...state,
                acceptQuote: false,
                acceptQuoteSuccess: null,
                acceptQuoteFailure: action.payload,
            };
        case orderTypes.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                updatingOrder: true,
                updateOrderSuccess: null,
                updateOrderFailure: null,
            };
        case orderTypes.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                updatingOrder: false,
                updateOrderSuccess: action.payload,
                updateOrderFailure: null,
            };
        case orderTypes.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                updatingOrder: false,
                updateOrderSuccess: null,
                updateOrderFailure: action.payload,
            };

        case orderTypes.UPDATE_RFQ_REQUEST:
            return {
                ...state,
                updatingRfq: true,
                updateRfqSuccess: null,
                updateRfqFailure: null,
            };
        case orderTypes.UPDATE_RFQ_SUCCESS:
            return {
                ...state,
                updatingRfq: false,
                updateRfqSuccess: action.payload,
                updateRfqFailure: null,
            };
        case orderTypes.UPDATE_RFQ_FAILURE:
            return {
                ...state,
                updatingRfq: false,
                updateRfqSuccess: null,
                updateRfqFailure: action.payload,
            };

        case orderTypes.SEND_RFQS_REQUEST:
            return {
                ...state,
                sendingRfqs: true,
                sendRfqsSuccess: null,
                sendRfqsFailure: null,
            };
        case orderTypes.SEND_RFQS_SUCCESS:
            return {
                ...state,
                sendingRfqs: false,
                sendRfqsSuccess: action.payload,
                sendRfqsFailure: null,
            };
        case orderTypes.SEND_RFQS_FAILURE:
            return {
                ...state,
                sendingRfqs: false,
                sendRfqsSuccess: null,
                sendRfqsFailure: action.payload,
            };

        case orderTypes.DELETE_RFQ_REQUEST:
            return {
                ...state,
                deletingRfq: true,
                deleteRfqSuccess: null,
                deleteRfqFailure: null,
            };
        case orderTypes.DELETE_RFQ_SUCCESS:
            return {
                ...state,
                deletingRfq: false,
                deleteRfqSuccess: action.payload,
                deleteRfqFailure: null,
            };
        case orderTypes.DELETE_RFQ_FAILURE:
            return {
                ...state,
                deletingRfq: false,
                deleteRfqSuccess: null,
                deleteRfqFailure: action.payload,
            };
        case orderTypes.CREATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                createPurchaseOrder: true,
                createPurchaseOrderSuccess: null,
                createPurchaseOrderFailure: null,
            };
        case orderTypes.CREATE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                createPurchaseOrder: false,
                createPurchaseOrderSuccess: action.payload,
                createPurchaseOrderFailure: null,
            };
        case orderTypes.CREATE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                createPurchaseOrder: false,
                createPurchaseOrderSuccess: null,
                createPurchaseOrderFailure: action.payload,
            };
        case orderTypes.UPDATE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                updatePurchaseOrder: true,
                updatePurchaseOrderSuccess: null,
                updatePurchaseOrderFailure: null,
            };
        case orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                updatePurchaseOrder: false,
                updatePurchaseOrderSuccess: action.payload,
                updatePurchaseOrderFailure: null,
            };
        case orderTypes.UPDATE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                updatePurchaseOrder: false,
                updatePurchaseOrderSuccess: null,
                updatePurchaseOrderFailure: action.payload,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                fetchingPurchaseOrder: true,
                fetchedPurchaseOrderSuccess: null,
                fetchedPurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                fetchingPurchaseOrder: false,
                fetchedPurchaseOrderSuccess: action.payload,
                fetchedPurchaseOrderFailure: null,
            };
        case orderTypes.FETCH_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                fetchingPurchaseOrder: false,
                fetchedPurchaseOrderSuccess: null,
                fetchedPurchaseOrderFailure: action.payload,
            };
        case orderTypes.SEND_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                sendPurchaseOrder: true,
                sendPurchaseOrderSuccess: null,
                sendPurchaseOrderFailure: null,
            };
        case orderTypes.SEND_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                sendPurchaseOrder: false,
                sendPurchaseOrderSuccess: action.payload,
                sendPurchaseOrderFailure: null,
            };
        case orderTypes.SEND_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                sendPurchaseOrder: false,
                sendPurchaseOrderSuccess: null,
                sendPurchaseOrderFailure: action.payload,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_REQUEST:
            return {
                ...state,
                fetchingApprovedResponses: true,
                fetchedApprovedResponsesSuccess: null,
                fetchedApprovedResponsesFailure: null,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_SUCCESS:
            return {
                ...state,
                fetchingApprovedResponses: false,
                fetchedApprovedResponsesSuccess: action.payload,
                fetchedApprovedResponsesFailure: null,
            };
        case orderTypes.FETCH_APPROVED_RESPONSE_FAILURE:
            return {
                ...state,
                fetchingApprovedResponses: false,
                fetchedApprovedResponsesSuccess: null,
                fetchedApprovedResponsesFailure: action.payload,
            };

        default:
            return state;
    }
};
