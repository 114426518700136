import config from "config/config";
import { authService } from "./authService";

export const vendorService = {
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
};

async function getLdbVendors(vendorLimit, search) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor?limit=${vendorLimit}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleVendor(vendorId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/single?vendorId=${vendorId}`,
        requestOptions
    );
    return res;
}

async function authenticateGmail() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/authenticate`,
        requestOptions
    );
    return res;
}

async function connectGoogleWorkspace(reqObj) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(reqObj),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/google/connect`,
        requestOptions
    );

    return res;
}

async function getAllChannels() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/social/channels`,
        requestOptions
    );

    return res;
}

async function getVendorsByOrderType(orderType, search) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/vendor/suggested?orderType=${orderType}${
            search ? "&search=" + search : ""
        }`,
        requestOptions
    );

    return res;
}
