import { chatTypes } from "redux/Ldb/types";

const initialState = {
    creatingConversation: false,
    createConversationSuccess: null,
    createConversationFailure: null,
    fetchingConversation: false,
    fetchConversationSuccess: null,
    fetchConversationFailure: null,
    sendingAiMessage: false,
    sendAiMessageSuccess: null,
    sendAiMessageFailure: null,
    updatingChatData: false,
    updateChatDataSuccess: null,
    updateChatDataFailure: null,
};

export const chat = (state = initialState, action) => {
    switch (action.type) {
        case chatTypes.CREATE_CONVERSATION_REQUEST:
            return {
                ...state,
                creatingConversation: true,
                createConversationSuccess: null,
                createConversationFailure: null,
            };
        case chatTypes.CREATE_CONVERSATION_SUCCESS:
            return {
                ...state,
                creatingConversation: false,
                createConversationSuccess: action.payload,
                createConversationFailure: null,
            };
        case chatTypes.CREATE_CONVERSATION_FAILURE:
            return {
                ...state,
                creatingConversation: false,
                createConversationSuccess: null,
                createConversationFailure: action.payload,
            };

        case chatTypes.FETCH_CONVERSATION_REQUEST:
            return {
                ...state,
                fetchingConversation: true,
                fetchConversationSuccess: null,
                fetchConversationFailure: null,
            };
        case chatTypes.FETCH_CONVERSATION_SUCCESS:
            return {
                ...state,
                fetchingConversation: false,
                fetchConversationSuccess: action.payload,
                fetchConversationFailure: null,
            };
        case chatTypes.FETCH_CONVERSATION_FAILURE:
            return {
                ...state,
                fetchingConversation: false,
                fetchConversationSuccess: null,
                fetchConversationFailure: action.payload,
            };

        case chatTypes.SEND_AI_CHAT_REQUEST:
            return {
                ...state,
                sendingAiMessage: true,
                sendAiMessageSuccess: null,
                sendAiMessageFailure: null,
            };
        case chatTypes.SEND_AI_CHAT_SUCCESS:
            return {
                ...state,
                sendingAiMessage: false,
                sendAiMessageSuccess: action.payload,
                sendAiMessageFailure: null,
            };
        case chatTypes.SEND_AI_CHAT_FAILURE:
            return {
                ...state,
                sendingAiMessage: false,
                sendAiMessageSuccess: null,
                sendAiMessageFailure: action.payload,
            };

        case chatTypes.UPDATE_CHAT_DATA_REQUEST:
            return {
                ...state,
                updatingChatData: true,
                updateChatDataSuccess: null,
                updateChatDataFailure: null,
            };
        case chatTypes.UPDATE_CHAT_DATA_SUCCESS:
            return {
                ...state,
                updatingChatData: false,
                updateChatDataSuccess: action.payload,
                updateChatDataFailure: null,
            };
        case chatTypes.UPDATE_CHAT_DATA_FAILURE:
            return {
                ...state,
                updatingChatData: false,
                updateChatDataSuccess: null,
                updateChatDataFailure: action.payload,
            };
        default:
            return state;
    }
};
