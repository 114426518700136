export const displayFileIcon = (ext, mode, className) => {
    switch (ext.toLowerCase()) {
        case "pdf":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/pdf_uploaded_icon_rudnrd.svg"
                    }
                    alt="pdf Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "png":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "jpg":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "jpeg":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "svg":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "tif":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "tiff":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "bmp":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "eps":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "psd":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687191068/Web%20App/external_pages/icons/image_uploaded_icon_wogklv.svg"
                    }
                    alt="pictorial Uploaded Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "gif":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/gif_yibt0r.svg"
                    }
                    alt="gif icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "pptx":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/pptx_fu4jbp.svg"
                    }
                    alt="pptx icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "ppt":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/pptx_fu4jbp.svg"
                    }
                    alt="pptx icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "odp":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/pptx_fu4jbp.svg"
                    }
                    alt="pptx icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "ods":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/spreadsheet_uysqjn.svg"
                    }
                    alt="spreadsheet icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "odt":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/document_ilemow.svg"
                    }
                    alt="Document Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "rtf":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/document_ilemow.svg"
                    }
                    alt="Document Icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "xls":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/spreadsheet_uysqjn.svg"
                    }
                    alt="spreadsheet icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "xlsx":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/spreadsheet_uysqjn.svg"
                    }
                    alt="spreadsheet icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "doc":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264153/Web%20App/dashboard/shipment_folder/word_awev5o.svg"
                    }
                    alt="word icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "docx":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264153/Web%20App/dashboard/shipment_folder/word_awev5o.svg"
                    }
                    alt="word icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "mp3":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "wav":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "aiff":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "aac":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "wma":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "ogg":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264156/Web%20App/dashboard/shipment_folder/audio_ik5vee.svg"
                    }
                    alt="audio icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "mp4":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "mov":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "wmv":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "flv":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "avi":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "avchd":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "webm":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "mkv":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        case "mpeg-2":
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/video_spgf60.svg"
                    }
                    alt="video icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );

        default:
            return (
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1687264154/Web%20App/dashboard/shipment_folder/generic_qv04dx.svg"
                    }
                    alt="generic icon"
                    className={`${
                        mode ? "w-4 h-4 max-sm:w-6 max-sm:h-6 max-sm:ml-[3px]" : "w-8 h-8"
                    } ${className}`}
                />
            );
    }
};
