/* eslint-disable no-unused-vars */
import { getFromStorage } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { authService } from "services/Ldb";

export const PublicRoutes = ({ children }) => {
    const location = useLocation();
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const { fetchUserProfileSuccess } = useSelector((state) => state.auth);

    const hasOnboarded =
        Boolean(fetchUserProfileSuccess?.profile?.businessName) &&
        Boolean(fetchUserProfileSuccess?.profile?.country) &&
        Boolean(fetchUserProfileSuccess?.profile?.businessType) &&
        Boolean(fetchUserProfileSuccess?.profile?.businessDoc) &&
        Boolean(fetchUserProfileSuccess?.profile?.address);

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-user"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (
        !profile?.user?.active &&
        authService.loggedIn() &&
        location.pathname !== `/registration-success/${profile?.token}`
    ) {
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }

    if (profile?.user?.active && authService.loggedIn() && hasOnboarded) {
        return <Navigate to={"/dashboard"} replace />;
    }

    if (profile?.user?.active && authService.loggedIn() && !hasOnboarded) {
        if (profile?.user?.role?.toLowerCase() === "owner") {
            return <Navigate to={"/onboarding"} replace />;
        } else {
            return <Navigate to={"/dashboard"} replace />;
        }
    }

    return children;
};

export const PublicVendorRoutes = ({ children }) => {
    const [profile, setProfile] = useState(() => getFromStorage("ally-vendor"));

    const savedToken = getFromStorage("vendor-reg-token");

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-vendor"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    //   if (!savedToken) return <Navigate to={"*"} replace />;
    // if (!authVendorService.loggedIn()) {
    //     if (!authVendorService.loggedIn() && Boolean(profile?.vendor)) {
    //         window.open(`/vendor/register?token=${savedToken}`, "_self");
    //     }

    //     window.open(`/vendor/register?token=${savedToken}`, "_self");
    //     return;
    // }

    return children;
};
