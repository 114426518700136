import config from "config/config";
import { authService } from "./authService";

export const chatService = {
    createConversation,
    getConversation,
    sendChat,
    updateChatData,
};

async function createConversation(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/conversation`,
        requestOptions
    );
    return res;
}

async function getConversation(conversationId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat?conversationId=${conversationId}`,
        requestOptions
    );
    return res;
}

async function sendChat(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/send-ai`,
        requestOptions
    );
    return res;
}

async function updateChatData(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/chat-data`,
        requestOptions
    );
    return res;
}
