import { authTypes } from "../types";


const initialState = {
    registeringUser: false,
    registerUserSuccess: null,
    registerUserFailure: null,
    verifyingUser: false,
    verifyUserSuccess: null,
    verifyUserFailure: null,
    resendingVerificationCode: false,
    resendVerificationCodeSuccess: null,
    resendVerificationCodeFailure: null,
    changingSignupEmail: false,
    changeSignupEmailSuccess: null,
    changeSignupEmailFailure: null,
    completeOnboarding: false,
    completeOnboardingSuccess: null,
    completeOnboardingFailure: null,
};

export const authVendor = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.REGISTER_VENDOR_REQUEST:
            return {
                ...state,
                registeringVendor: true,
                registerVendorSuccess: null,
                registerVendorFailure: null,
            };
        case authTypes.REGISTER_VENDOR_SUCCESS:
            return {
                ...state,
                registeringVendor: false,
                registerVendorSuccess: action.payload,
                registerVendorFailure: null,
            };
        case authTypes.REGISTER_VENDOR_FAILURE:
            return {
                ...state,
                registeringVendor: false,
                registerVendorSuccess: null,
                registerVendorFailure: action.payload,
            };

        case authTypes.VERIFY_VENDOR_REQUEST:
            return {
                ...state,
                verifyingVendor: true,
                verifyVendorSuccess: null,
                verifyVendorFailure: null,
            };
        case authTypes.VERIFY_VENDOR_SUCCESS:
            return {
                ...state,
                verifyingVendor: false,
                verifyVendorSuccess: action.payload,
                verifyVendorFailure: null,
            };
        case authTypes.VERIFY_VENDOR_FAILURE:
            return {
                ...state,
                verifyingVendor: false,
                verifyVendorSuccess: null,
                verifyVendorFailure: action.payload,
            };

        case authTypes.RESEND_VERIFY_CODE_REQUEST:
            return {
                ...state,
                resendingVerificationCode: true,
                resendVerificationCodeSuccess: null,
                resendVerificationCodeFailure: null,
            };
        case authTypes.RESEND_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                resendingVerificationCode: false,
                resendVerificationCodeSuccess: action.payload,
                resendVerificationCodeFailure: null,
            };
        case authTypes.RESEND_VERIFY_CODE_FAILURE:
            return {
                ...state,
                resendingVerificationCode: false,
                resendVerificationCodeSuccess: null,
                resendVerificationCodeFailure: action.payload,
            };
        case authTypes.CHANGE_SIGNUP_EMAIL_REQUEST:
            return {
                ...state,
                changingSignupEmail: true,
                changeSignupEmailSuccess: null,
                changeSignupEmailFailure: null,
            };
        case authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS:
            return {
                ...state,
                changingSignupEmail: false,
                changeSignupEmailSuccess: action.payload,
                changeSignupEmailFailure: null,
            };
        case authTypes.CHANGE_SIGNUP_EMAIL_FAILURE:
            return {
                ...state,
                changingSignupEmail: false,
                changeSignupEmailSuccess: null,
                changeSignupEmailFailure: action.payload,
            };

        case authTypes.COMPLETE_ONBOARDING_REQUEST:
            return {
                ...state,
                completeOnboarding: true,
                completeOnboardingSuccess: null,
                completeOnboardingFailure: null,
            };
        case authTypes.COMPLETE_ONBOARDING_SUCCESS:
            return {
                ...state,
                completeOnboarding: false,
                completeOnboardingSuccess: action.payload,
                completeOnboardingFailure: null,
            };
        case authTypes.COMPLETE_ONBOARDING_FAILURE:
            return {
                ...state,
                completeOnboarding: false,
                completeOnboardingSuccess: null,
                completeOnboardingFailure: action.payload,
            };

        default:
            return state;
    }
};