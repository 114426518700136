export const saveToStorage = (name, data, shouldRemember) => {
    if (shouldRemember) {
        removeFromStorage(name);
        localStorage.setItem(name, JSON.stringify(data));
    } else {
        sessionStorage.setItem(name, JSON.stringify(data));
    }
};

export const getFromStorage = (name) => {
    return JSON.parse(localStorage.getItem(name) || sessionStorage.getItem(name));
};

export const removeFromStorage = (name) => {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
};
