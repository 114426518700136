import { configureStore } from "@reduxjs/toolkit";
import { authVendor } from "redux/Vendor/reducers";
import { auth, vendor, order, chat, workFlow} from "redux/Ldb/reducers";

const store = configureStore({
    reducer: {
        auth,
        authVendor,
        vendor,
        order,
        chat,
        workFlow,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: true, immutableCheck: true }),
});

export default store;
