import Loader from "components/common/Loader";
import { PrivateRoutes, CheckTokenFromRoutes } from "components/common/Routes/PrivateRoute";
import { PublicRoutes, PublicVendorRoutes } from "components/common/Routes/PublicRoute";
import Dashboard from "pages/Ldb/Dashboard";
import { Suspense, lazy } from "react";

// Public Pages
const Home = lazy(() => import("pages/Ldb/Landing/Home"));
const About = lazy(() => import("pages/Ldb/Landing/About"));
const FAQs = lazy(() => import("pages/Ldb/Landing/FAQs"));
const CookiePolicy = lazy(() => import("pages/Ldb/Landing/CookiePolicy"));
const Login = lazy(() => import("pages/Ldb/Auth/Login"));
const Signup = lazy(() => import("pages/Ldb/Auth/Signup"));
const ForgotPassword = lazy(() => import("pages/Ldb/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/Ldb/Auth/ResetPassword"));
const SignupSuccess = lazy(() => import("pages/Ldb/Auth/SignupSuccess"));
const VendorSignupSuccess = lazy(() => import("pages/Vendor/Auth/SignupSuccess"));
const AcctVerification = lazy(() => import("pages/Ldb/Auth/AcctVerification"));
const VendorAcctVerification = lazy(() => import("pages/Vendor/Auth/AcctVerification"));
const ChangeVerificationEmail = lazy(() => import("pages/Ldb/Auth/ChangeVerificationEmail"));
const VendorChangeVerificationEmail = lazy(() =>
    import("pages/Vendor/Auth/ChangeVerificationEmail")
);

const GoogleAuth = lazy(() => import("pages/Ldb/Auth/GoogleAuth"));

// Private Pages
const VendorSignup = lazy(() => import("pages/Vendor/Auth/Signup"));
const Onboarding = lazy(() => import("pages/Ldb/Onboarding"));
// const Dashboard = lazy(() => import("pages/Ldb/Dashboard"));
const DashboardHome = lazy(() => import("components/views/Ldb/Dashboard/Home"));
const VendorList = lazy(() => import("pages/Ldb/VendorManagement/VendorList"));
const Shipment = lazy(() => import("pages/Ldb/Shipment/index"));
const ShipmentDetails = lazy(() => import("pages/Ldb/Shipment/ShipmentDetails"));
const VendorDetails = lazy(() => import("pages/Ldb/VendorManagement/VendorDetails"));
const AddVendor = lazy(() => import("pages/Ldb/VendorManagement/AddVendor"));
const VendorAnalytics = lazy(() => import("pages/Ldb/VendorManagement/VendorAnalytics"));
const Rfqs = lazy(() => import("pages/Ldb/Orders/Rfqs"));
const Copilot = lazy(() => import("pages/Ldb/Copilot"));
const OrderDetails = lazy(() => import("pages/Ldb/Orders/Rfqs/OrderDetails"));
const RfqDetails = lazy(() => import("pages/Ldb/Orders/Rfqs/RfqDetails"));
const Quotes = lazy(() => import("pages/Ldb/Orders/Quotes/index"));
const QuoteDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteDetails"));
const QuoteItemDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteItemDetails"));
const QuoteVendorDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteVendorDetails"));
const VendorOnboarding = lazy(() => import("pages/Vendor/Auth/Onboarding"));
const TeamManagement = lazy(() => import("pages/Ldb/TeamManagement/index"));
const Workflow = lazy(() => import("pages/Ldb/Workflow/index"));
const WorkflowRun = lazy(() => import("pages/Ldb/Workflow/WorkFlowRun"));
const WorkflowGraph = lazy(() => import("pages/Ldb/Workflow/WorkflowGraph"));
const Settings = lazy(() => import("pages/Ldb/Settings"));
const PurchaseOrder = lazy(() => import("pages/Ldb/Orders/PurchaseOrder/index"));
const CreatePurchaseOrder = lazy(() => import("pages/Ldb/Orders/PurchaseOrder/CreateOrders"));

const CreateRfqs = lazy(() => import("pages/Ldb/Orders/Rfqs/CreateRfqs"));

const routes = [
    {
        path: "/",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <Home />
            </Suspense>
        ),
    },
    {
        path: "/about",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <About />
            </Suspense>
        ),
    },
    {
        path: "/faqs",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <FAQs />
            </Suspense>
        ),
    },
    {
        path: "/cookie-policy",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <CookiePolicy />
            </Suspense>
        ),
    },
    {
        path: "/login",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <Login />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/register",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <Signup />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/register",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicVendorRoutes>
                    <VendorSignup />
                </PublicVendorRoutes>
            </Suspense>
        ),
    },
    {
        path: "/forgot-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ForgotPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/reset-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ResetPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/registration-success/:token",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <SignupSuccess />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/registration-success",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicVendorRoutes>
                    <VendorSignupSuccess />
                </PublicVendorRoutes>
            </Suspense>
        ),
    },
    {
        path: "/verify",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <AcctVerification />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/verify-email",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <CheckTokenFromRoutes>
                    <VendorAcctVerification />
                </CheckTokenFromRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/verify",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <CheckTokenFromRoutes>
                    <VendorAcctVerification />
                </CheckTokenFromRoutes>
            </Suspense>
        ),
    },
    {
        path: "/change-email",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ChangeVerificationEmail />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/change-email",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicVendorRoutes>
                    <VendorChangeVerificationEmail />
                </PublicVendorRoutes>
            </Suspense>
        ),
    },
    {
        path: "/google-auth",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <GoogleAuth />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/onboarding",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PrivateRoutes>
                    <Onboarding />
                </PrivateRoutes>
            </Suspense>
        ),
    },
    {
        path: "/vendor/onboarding",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicVendorRoutes>
                    <VendorOnboarding />
                </PublicVendorRoutes>
            </Suspense>
        ),
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
            {
                index: true,
                element: <DashboardHome />,
            },
            {
                path: "workflow",
                element: <Workflow />,
            },
            {
                path: "workflow/run/:workflowId",
                element: <WorkflowRun />,
            },
            {
                path: "workflow/:workflowId",
                element: <WorkflowGraph />,
            },
            {
                path: "vendors",
                element: <VendorList />,
            },
            {
                path: "vendors/add-vendor",
                element: <AddVendor />,
            },
            {
                path: "vendor/:vendorId",
                element: <VendorDetails />,
            },
            {
                path: "vendors/analytics",
                element: <VendorAnalytics />,
            },
            {
                path: "shipment",
                element: <Shipment />,
            },
            {
                path: "shipment/:shipmentId",
                element: <ShipmentDetails />,
            },
            {
                path: "rfqs",
                element: <Rfqs />,
            },
            {
                path: "co-pilot",
                element: <Copilot />,
            },
            {
                path: "quotes",
                element: <Quotes />,
            },
            {
                path: "quotes/:quoteId",
                element: <QuoteDetails />,
            },
            {
                path: "quotes/:quoteId/responses",
                element: <QuoteItemDetails />,
            },
            {
                path: "order/:orderId",
                element: <OrderDetails />,
            },
            {
                path: "quotes/:quoteId/item/vendor-detail",
                element: <QuoteVendorDetails />,
            },
            {
                path: "purchase-orders",
                element: <PurchaseOrder />,
            },
            {
                path: "purchase-orders/create",
                element: <CreatePurchaseOrder />,
            },
            {
                path: "rfq/:rfqId",
                element: <RfqDetails />,
            },
            {
                path: "rfqs/create",
                element: <CreateRfqs />,
            },
            {
                path: "team-management",
                element: <TeamManagement />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
        ],
    },
    {
        path: "*",
        element: (
            <div className="h-screen flex flex-col items-center justify-center">
                <p className="font-rocGroteskMedium text-2xl mb-5">404</p>
                <p className="font-rocGroteskMedium text-lg mb-5">PAGE NOT FOUND</p>
            </div>
        ),
    },
];

export default routes;
