export const workFlowTypes = {
    FETCH_WORKFLOW_LIST_REQUEST: "FETCH_WORKFLOW_LIST_REQUEST",
    FETCH_WORKFLOW_LIST_SUCCESS: "FETCH_WORKFLOW_LIST_SUCCESS",
    FETCH_WORKFLOW_LIST_FAILURE: "FETCH_WORKFLOW_LIST_FAILURE",
    FETCH_WORKFLOW_RUNS_REQUEST: "FETCH_WORKFLOW_RUNS_REQUEST",
    FETCH_WORKFLOW_RUNS_SUCCESS: "FETCH_WORKFLOW_RUNS_SUCCESS",
    FETCH_WORKFLOW_RUNS_FAILURE: "FETCH_WORKFLOW_RUNS_FAILURE",
    CREATE_WORKFLOW_REQUEST: "CREATE_WORKFLOW_REQUEST",
    CREATE_WORKFLOW_SUCCESS: "CREATE_WORKFLOW_SUCCESS",
    CREATE_WORKFLOW_FAILURE: "CREATE_WORKFLOW_FAILURE",
    DELETE_WORKFLOW_REQUEST: "DELETE_WORKFLOW_REQUEST",
    DELETE_WORKFLOW_SUCCESS: "DELETE_WORKFLOW_SUCCESS",
    DELETE_WORKFLOW_FAILURE: "DELETE_WORKFLOW_FAILURE",
    PLAY_PAUSE_WORKFLOW_REQUEST: "PLAY_PAUSE_WORKFLOW_REQUEST",
    PLAY_PAUSE_WORKFLOW_SUCCESS: "PLAY_PAUSE_WORKFLOW_SUCCESS",
    PLAY_PAUSE_WORKFLOW_FAILURE: "PLAY_PAUSE_WORKFLOW_FAILURE",
};
