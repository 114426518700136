import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const orderService = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSingleOrder,
    getQuotes,
    getSingleQuotes,
    getSingleQuoteResponses,
    acceptQuote,
    updateOrder,
    updateRfq,
    sendRfqs,
    deleteRfq,
    createPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
};

async function getRfqs(rfqLimit, search, vendor, orderId, amount) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        vendor,
        taId: orderId,
        amount,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/order/rfqs?limit=${rfqLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleRfq(rfqId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/single?rfqId=${rfqId}`,
        requestOptions
    );
    return res;
}

async function getOrders(orderLimit) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/order?limit=${orderLimit}`,
        requestOptions
    );
    return res;
}

async function getSingleOrder(orderId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/single/order?orderId=${orderId}`,
        requestOptions
    );
    return res;
}

async function updateOrder(orderId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/order/${orderId}`,
        requestOptions
    );
    return res;
}

async function updateRfq(rfqId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/update/rfq/${rfqId}`,
        requestOptions
    );
    return res;
}

async function getQuotes(quoteLimit, search, vendor, quoteId, amount) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        vendor,
        taId: quoteId,
        amount,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quotes/list?limit=${quoteLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleQuotes(quoteId) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        quoteId,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quotes/rfqs?${urlParams?.toString()}`,
        requestOptions
    );
    return res;
}

async function getApprovedResponses(limit, search) {
    const requestOptions = {
        method: "GET",
    };

      const urlParams = createUrlSearchParams({
          search,
      });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/approved/quoteResponse?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}


async function getSingleQuoteResponses(quoteId, responseLimit) {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/${quoteId}/quoteResponses?limit=${responseLimit}`,
        requestOptions
    );
    return res;
}

async function sendRfqs(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/create/quote/rfqs`,
        requestOptions
    );
    return res;
}

async function acceptQuote(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/quote/acceptance`,
        requestOptions
    );

    return res;
}
async function deleteRfq(rfqId) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/rfq/delete/${rfqId}`,
        requestOptions
    );
    return res;
}

async function createPurchaseOrder(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/po`, requestOptions);

    return res;
}

async function updatePurchaseOrder(poId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/update/${poId}`,
        requestOptions
    );
    return res;
}
async function getPurchaseOrder(poLimit, search, taId) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search,
        taId,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/list?limit=${poLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function sendPurchaseOrder(poId, data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/po/send/purchase-order/${poId}`,
        requestOptions
    );

    return res;
}