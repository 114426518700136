export const authTypes = {
    REGISTER_VENDOR_REQUEST: "REGISTER_VENDOR_REQUEST",
    REGISTER_VENDOR_SUCCESS: "REGISTER_VENDOR_SUCCESS",
    REGISTER_VENDOR_FAILURE: "REGISTER_VENDOR_FAILURE",
    VERIFY_VENDOR_REQUEST: "VERIFY_VENDOR_REQUEST",
    VERIFY_VENDOR_SUCCESS: "VERIFY_VENDOR_SUCCESS",
    VERIFY_VENDOR_FAILURE: "VERIFY_VENDOR_FAILURE",
    RESEND_VERIFY_CODE_REQUEST: "RESEND_VERIFY_CODE_REQUEST",
    RESEND_VERIFY_CODE_SUCCESS: "RESEND_VERIFY_CODE_SUCCESS",
    RESEND_VERIFY_CODE_FAILURE: "RESEND_VERIFY_CODE_FAILURE",
    CHANGE_SIGNUP_EMAIL_REQUEST: "CHANGE_SIGNUP_EMAIL_REQUEST",
    CHANGE_SIGNUP_EMAIL_SUCCESS: "CHANGE_SIGNUP_EMAIL_SUCCESS",
    CHANGE_SIGNUP_EMAIL_FAILURE: "CHANGE_SIGNUP_EMAIL_FAILURE",
    COMPLETE_ONBOARDING_REQUEST: "COMPLETE_ONBOARDING_REQUEST",
    COMPLETE_ONBOARDING_SUCCESS: "COMPLETE_ONBOARDING_SUCCESS",
    COMPLETE_ONBOARDING_FAILURE: "COMPLETE_ONBOARDING_FAILURE",
};
