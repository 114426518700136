import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const workFlowService = {
    getWorkFlowList,
    createWorkFlow,
    deleteWorkFlow,
    pauseOrPlayWorkFlow,
};

async function getWorkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        WorkflowName,
        createdBy,
        numOfRuns,
        dateCreated,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list?limit=${workflowLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function createWorkFlow(data) {
 const requestOptions = {
     method: "POST",
     body: JSON.stringify(data),
 };

 const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/workFlow`, requestOptions);

 return res;
}
async function deleteWorkFlow(workFlowId) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/delete/workFlow/${workFlowId}`,
        requestOptions
    );
    return res;
}

async function pauseOrPlayWorkFlow(workflowId, data) {
   const requestOptions = {
       method: "PUT",
       body: JSON.stringify(data),
   };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/play-or-pause/workFlow/${workflowId}`,
        requestOptions
    );
    return res;
}
