export const orderTypes = {
    FETCH_RFQS_REQUEST: "FETCH_RFQS_REQUEST",
    FETCH_RFQS_SUCCESS: "FETCH_RFQS_SUCCESS",
    FETCH_RFQS_FAILURE: "FETCH_RFQS_FAILURE",
    FETCH_ORDERS_REQUEST: "FETCH_ORDERS_REQUEST",
    FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
    FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE",
    FETCH_SINGLE_RFQ_REQUEST: "FETCH_SINGLE_RFQ_REQUEST",
    FETCH_SINGLE_RFQ_SUCCESS: "FETCH_SINGLE_RFQ_SUCCESS",
    FETCH_SINGLE_RFQ_FAILURE: "FETCH_SINGLE_RFQ_FAILURE",
    FETCH_SINGLE_ORDER_REQUEST: "FETCH_SINGLE_ORDER_REQUEST",
    FETCH_SINGLE_ORDER_SUCCESS: "FETCH_SINGLE_ORDER_SUCCESS",
    FETCH_SINGLE_ORDER_FAILURE: "FETCH_SINGLE_ORDER_FAILURE",
    FETCH_QUOTES_REQUEST: "FETCH_QUOTES_REQUEST",
    FETCH_QUOTES_SUCCESS: "FETCH_QUOTES_SUCCESS",
    FETCH_QUOTES_FAILURE: "FETCH_QUOTES_FAILURE",
    FETCH_SINGLE_QUOTES_REQUEST: "FETCH_SINGLE_QUOTES_REQUEST",
    FETCH_SINGLE_QUOTES_SUCCESS: "FETCH_SINGLE_QUOTES_SUCCESS",
    FETCH_SINGLE_QUOTES_FAILURE: "FETCH_SINGLE_QUOTES_FAILURE",
    FETCH_SINGLE_QUOTE_RESPONSES_REQUEST: "FETCH_SINGLE_QUOTE_RESPONSES_REQUEST",
    FETCH_SINGLE_QUOTE_RESPONSES_SUCCESS: "FETCH_SINGLE_QUOTE_RESPONSES_SUCCESS",
    FETCH_SINGLE_QUOTE_RESPONSES_FAILURE: "FETCH_SINGLE_QUOTE_RESPONSES_FAILURE",
    ACCEPT_QUOTE_REQUEST: "ACCEPT_QUOTE_REQUEST",
    ACCEPT_QUOTE_SUCCESS: "ACCEPT_QUOTE_SUCCESS",
    ACCEPT_QUOTE_FAILURE: "ACCEPT_QUOTE_FAILURE",
    UPDATE_RFQ_REQUEST: "UPDATE_RFQ_REQUEST",
    UPDATE_RFQ_SUCCESS: "UPDATE_RFQ_SUCCESS",
    UPDATE_RFQ_FAILURE: "UPDATE_RFQ_FAILURE",
    UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
    UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
    UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
    SEND_RFQS_REQUEST: "SEND_RFQS_REQUEST",
    SEND_RFQS_SUCCESS: "SEND_RFQS_SUCCESS",
    SEND_RFQS_FAILURE: "SEND_RFQS_FAILURE",
    DELETE_RFQ_REQUEST: "DELETE_RFQ_REQUEST",
    DELETE_RFQ_SUCCESS: "DELETE_RFQ_SUCCESS",
    DELETE_RFQ_FAILURE: "DELETE_RFQ_FAILURE",
    CREATE_PURCHASE_ORDER_REQUEST: "CREATE_PURCHASE_ORDER_REQUEST",
    CREATE_PURCHASE_ORDER_SUCCESS: "CREATE_PURCHASE_ORDER_SUCCESS",
    CREATE_PURCHASE_ORDER_FAILURE: "CREATE_PURCHASE_ORDER_FAILURE",
    UPDATE_PURCHASE_ORDER_REQUEST: "UPDATE_PURCHASE_ORDER_REQUEST",
    UPDATE_PURCHASE_ORDER_SUCCESS: "UPDATE_PURCHASE_ORDER_SUCCESS",
    UPDATE_PURCHASE_ORDER_FAILURE: "UPDATE_PURCHASE_ORDER_FAILURE",
    FETCH_PURCHASE_ORDER_REQUEST: "FETCH_PURCHASE_ORDER_REQUEST",
    FETCH_PURCHASE_ORDER_SUCCESS: "FETCH_PURCHASE_ORDER_SUCCESS",
    FETCH_PURCHASE_ORDER_FAILURE: "FETCH_PURCHASE_ORDER_FAILURE",
    SEND_PURCHASE_ORDER_REQUEST: "SEND_PURCHASE_ORDER_REQUEST",
    SEND_PURCHASE_ORDER_SUCCESS: "SEND_PURCHASE_ORDER_SUCCESS",
    SEND_PURCHASE_ORDER_FAILURE: "SEND_PURCHASE_ORDER_FAILURE",
    FETCH_APPROVED_RESPONSE_REQUEST: "FETCH_APPROVED_RESPONSE_REQUEST",
    FETCH_APPROVED_RESPONSE_SUCCESS: "FETCH_APPROVED_RESPONSE_SUCCESS",
    FETCH_APPROVED_RESPONSE_FAILURE: "FETCH_APPROVED_RESPONSE_FAILURE",
};
