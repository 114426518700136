export const vendorTypes = {
    FETCH_LDB_VENDORS_REQUEST: "FETCH_LDB_VENDORS_REQUEST",
    FETCH_LDB_VENDORS_SUCCESS: "FETCH_LDB_VENDORS_SUCCESS",
    FETCH_LDB_VENDORS_FAILURE: "FETCH_LDB_VENDORS_FAILURE",
    FETCH_SINGLE_VENDOR_REQUEST: "FETCH_SINGLE_VENDOR_REQUEST",
    FETCH_SINGLE_VENDOR_SUCCESS: "FETCH_SINGLE_VENDOR_SUCCESS",
    FETCH_SINGLE_VENDOR_FAILURE: "FETCH_SINGLE_VENDOR_FAILURE",
    AUTHENTICATE_GMAIL_REQUEST: "AUTHENTICATE_GMAIL_REQUEST",
    AUTHENTICATE_GMAIL_SUCCESS: "AUTHENTICATE_GMAIL_SUCCESS",
    AUTHENTICATE_GMAIL_FAILURE: "AUTHENTICATE_GMAIL_FAILURE",
    CONNECT_GOOGLE_WORKSPACE_REQUEST: "CONNECT_GOOGLE_WORKSPACE_REQUEST",
    CONNECT_GOOGLE_WORKSPACE_SUCCESS: "CONNECT_GOOGLE_WORKSPACE_SUCCESS",
    CONNECT_GOOGLE_WORKSPACE_FAILURE: "CONNECT_GOOGLE_WORKSPACE_FAILURE",
    FETCH_ALL_CHANNELS_REQUEST: "FETCH_ALL_CHANNELS_REQUEST",
    FETCH_ALL_CHANNELS_SUCCESS: "FETCH_ALL_CHANNELS_SUCCESS",
    FETCH_ALL_CHANNELS_FAILURE: "FETCH_ALL_CHANNELS_FAILURE",
    FETCH_VENDORS_BY_ORDER_TYPE_REQUEST: "FETCH_VENDORS_BY_ORDER_TYPE_REQUEST",
    FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS: "FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS",
    FETCH_VENDORS_BY_ORDER_TYPE_FAILURE: "FETCH_VENDORS_BY_ORDER_TYPE_FAILURE",
};
