import DashboardSidebar from "components/common/DashboardSidebar";
import Loader from "components/common/Loader";
import DashboardNav from "components/common/Navbar/DashboardNav";
import React, { Suspense } from "react";

const DashboardFrame = ({ children }) => {

    return (
        <div className="bg-body-1 w-full min-h-screen overflow-x-hidden">
            <DashboardNav />
            <div className="grid grid-cols-[17%_auto] h-[calc(100vh-84px)]">
                <DashboardSidebar />
                <div id="dashboardContent" className="px-4 pt-6 pb-6 w-full overflow-y-scroll">
                    <Suspense
                        fallback={
                            <div className="h-full flex items-center justify-center">
                                <Loader color={"gm-50"} size={8} />
                            </div>
                        }
                    >
                        {children}
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default DashboardFrame;
