import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { vendorActions } from "redux/Ldb/actions";
import { sidebarLinks } from "variables/sidebarLinks";

const DashboardSidebar = () => {
    const [isHoveredId, setIsHoveredId] = useState(-1);
    const [activeSideLink, setActiveSideLink] = useState("");
    const [activeSidebarId, setActiveSidebarId] = useState(-1);
    const location = useLocation();
    const dispatch = useDispatch();

    const { fetchedAllChannelsSuccess } = useSelector((state) => state.vendor);

    const channelsSynced = fetchedAllChannelsSuccess?.channel?.map(
        (channel) => channel?.synced ?? false
    );

    const LinkItem = ({ item, idx }) => {
        const openChildren =
            typeof item?.link === "string"
                ? item?.link.includes(activeSideLink)
                : item?.link
                      ?.map((path) => activeSideLink?.startsWith?.(path))
                      ?.find((value) => Boolean(value));

        return !item?.children ? (
            <NavLink
                to={item.link}
                onClick={() => {
                    setActiveSideLink(item.link);
                    setActiveSidebarId(idx);
                }}
                end
                onMouseEnter={() => setIsHoveredId(idx)}
                onMouseLeave={() => setIsHoveredId(-1)}
                className={({ isActive }) =>
                    `flex px-3 py-2.5 hover:bg-slate-50 ${isActive && "bg-slate-50"} cursor-pointer`
                }
            >
                {({ isActive }) => (
                    <div className="flex gap-2 items-center">
                        {item?.icons && (
                            <i
                                className={`${item.icons} ${
                                    isHoveredId === idx ? "text-g-60" : "text-slate-500"
                                } ${isActive && "!text-g-60"} `}
                            ></i>
                        )}
                        <p
                            className={`text-sm ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${isActive && "!text-g-60"} font-rocGroteskMedium`}
                        >
                            {item.title}
                        </p>
                    </div>
                )}
            </NavLink>
        ) : (
            <div>
                <div
                    onClick={() => {
                        setActiveSideLink(item.link);
                        setActiveSidebarId(idx);
                    }}
                    onMouseEnter={() => setIsHoveredId(idx)}
                    onMouseLeave={() => setIsHoveredId(-1)}
                    className={`flex gap-2 items-center px-3 py-2.5 hover:bg-slate-50 ${
                        (String(activeSidebarId).includes(String(idx)) ||
                            item?.link.includes(activeSideLink) ||
                            openChildren) &&
                        "bg-slate-50"
                    } cursor-pointer`}
                >
                    {item?.icons && (
                        <i
                            className={`${item.icons} ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${
                                (String(activeSidebarId).includes(String(idx)) ||
                                    item?.link.includes(activeSideLink) ||
                                    openChildren) &&
                                "!text-g-60"
                            }`}
                        ></i>
                    )}
                    <p
                        className={`text-sm ${
                            isHoveredId === idx ? "text-g-60" : "text-slate-500"
                        } ${
                            (String(activeSidebarId).includes(String(idx)) ||
                                item?.link.includes(activeSideLink) ||
                                openChildren) &&
                            "!text-g-60"
                        } font-rocGroteskMedium`}
                    >
                        {item.title}
                    </p>
                </div>
                {(String(activeSidebarId).includes(String(idx)) ||
                    item?.link.includes(activeSideLink) ||
                    openChildren) && (
                    <div className="pl-4 mt-1">
                        {item?.children?.map((child, index) => {
                            return (
                                <LinkItem
                                    key={child?.title + index}
                                    item={child}
                                    idx={idx + "-" + index}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        const pathArr = location.pathname
            ?.split("/")
            ?.filter((str) => str !== "")
            ?.slice(1);

        if (pathArr?.length > 0) {
            setActiveSideLink("/" + pathArr?.join("/"));
        } else {
            setActiveSidebarId(0);
        }
    }, [location.pathname]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    return (
        <div className="bg-white py-6 px-4 cursor-pointer relative flex flex-col justify-between gap-y-1 border-r border-slate-100 h-full">
            <div className="flex z-30 items-center absolute bg-white right-[-14px] cursor-pointer justify-center w-[28px] h-[28px] px-1.5 rounded-full border border-n-20 shadow-boxShadow">
                <i className="ri-arrow-left-s-line text-slate-400"></i>
            </div>
            <div className="flex flex-col gap-y-1">
                {sidebarLinks?.map((item, idx) => {
                    return <LinkItem key={item.title + idx} item={item} idx={idx} />;
                })}
            </div>

            {channelsSynced?.includes(false) && (
                <div className="shadow-boxShadow-3 w-full bg-white border border-slate-100 rounded py-2.5 px-3 mb-2">
                    <div className="flex items-center justify-between ">
                        <p className="text-gm-50 text-sm font-rocGroteskMedium">
                            Importing Contacts
                        </p>
                        {/* <i className="ri-close-fill text-lg"></i> */}
                    </div>
                    <p className="text-[13px] text-slate-500 font-rocGroteskMedium ">
                        Co-Pilot is trying to import your existing vendor contacts
                    </p>
                    {/* <p className="mt-2 flex items-center text-g-50 gap-1.5">
                    <span className="text-[13px] ">Try importing now</span>
                    <i className="ri-arrow-right-line text-sm"></i>
                </p> */}
                </div>
            )}
        </div>
    );
};

export default DashboardSidebar;
