import CustomToast from "components/common/CustomToast";
import {  workFlowTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { workFlowService } from "services/Ldb";

export const workFlowActions = {
    getworkFlowList,
    pauseOrPlayWorkFlow,
    deleteWorkFlow,
    resetDeleteWorkFlowSuccess,
};

function getworkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_LIST_REQUEST));

        workFlowService
            .getWorkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated)
            .then(
                (res) => {
                    dispatch(success(workFlowTypes.FETCH_WORKFLOW_LIST_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(failure(workFlowTypes.FETCH_WORKFLOW_LIST_FAILURE, error?.message));
                    }
                }
            );
    };
}


function pauseOrPlayWorkFlow(workflowId, data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.PLAY_PAUSE_WORKFLOW_REQUEST));

        workFlowService.pauseOrPlayWorkFlow(workflowId, data).then(
            (res) => {
                dispatch(success(workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.PLAY_PAUSE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteWorkFlow(workflowId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.DELETE_WORKFLOW_REQUEST));

        workFlowService.deleteWorkFlow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Workflow deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_WORKFLOW_FAILURE, error?.message));
                }
            }
        );
    };
}




function resetDeleteWorkFlowSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
