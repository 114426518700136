import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "routes";

function App() {
    const allRoutes = useRoutes(routes);

    return (
        <>
            <Toaster position="top-right" />

            <div className="App">{allRoutes}</div>
        </>
    );
}

export default App;
